import React from "react";
import { connect } from "react-redux";
import {
    Button,
    Label,
    FormGroup,
    Input,
    FormText,
    Card,
    CardBody,
    Row,
    Col
} from "reactstrap";
import Loader from "react-loader-spinner";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
import axios from "axios";
import { config } from "../../config";
import Loader2 from "../components/Loading";

class AlterarSenha extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            usuario: this.props.user,
            loading: false,
            newPassword: "",
            confirmPassword: "",
        };
    }

    changePassword = (e) => {
        e.preventDefault();
        if(this.state.newPassword == this.state.confirmPassword){
            this.setState({
                loading: true,
            }, ()=> this.setNewPassword());
        }else{
            var options = {};
            options = {
                place: "tc",
                message: (
                    <div>
                        <div>
                            A nova senha e a confirmação não são iguais!
                        </div>
                    </div>
                ),
                type: "danger",
                icon: "now-ui-icons ui-1_bell-53",
                autoDismiss: 7
            };
            if (this.refs.notificationAlert) {
                this.refs.notificationAlert.notificationAlert(options);
            }
        }
    }

    changePasswordValue = () => ({ target: { name, value } }) =>  {
        this.setState({
            [name]: value,
        });
    }

    setNewPassword = () => {
        axios.patch(config.API_AUTH + this.state.usuario.id + "/update", { password: this.state.newPassword }, {auth: { username: config.BEVI_USER, password: config.BEVI_PASS }})
        .then(response => {
            if(response.status == 204){
                this.setState({
                    loading: false,
                    newPassword: "",
                    confirmPassword: ""
                });
    
                var options = {};
                options = {
                    place: "tc",
                    message: (
                        <div>
                            <div>
                                Senha alterada com sucesso!
                            </div>
                        </div>
                    ),
                    type: "success",
                    icon: "now-ui-icons ui-1_bell-53",
                    autoDismiss: 7
                };
                if (this.refs.notificationAlert) {
                    this.refs.notificationAlert.notificationAlert(options);
                }
            }
            
        })
        .catch(error => {
            this.setState({
                loading: false,
            });

            var options = {};
            options = {
                place: "tc",
                message: (
                    <div>
                        <div>
                            Erro ao tentar alterar senha, por favor tente novamente mais tarde!
                        </div>
                    </div>
                ),
                type: "danger",
                icon: "now-ui-icons ui-1_bell-53",
                autoDismiss: 7
            };
            if (this.refs.notificationAlert) {
                this.refs.notificationAlert.notificationAlert(options);
            }
        });
    }

    render() {
        return (
            <div className="content">
                {this.state.loading && (
                    <Loader2 />
                )}

                <NotificationAlert ref="notificationAlert" />

                <h4>Alterar senha</h4>

                <Row>
                    <Col md={12}>
                        <Card>
                            <CardBody>
                                <form onSubmit={this.changePassword}>
                                    <Row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="exampleEmail">Nova senha</Label>
                                                <Input
                                                    type="password"
                                                    name="newPassword"
                                                    value={this.state.newPassword}
                                                    onChange={this.changePasswordValue()}
                                                    required
                                                />
                                                <FormText color="muted">

                                                </FormText>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="exampleEmail">Repetir senha</Label>
                                                <Input
                                                    type="password"
                                                    name="confirmPassword"
                                                    value={this.state.confirmPassword}
                                                    onChange={this.changePasswordValue()}
                                                    required
                                                />
                                                <FormText color="muted">

                                                </FormText>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Button color="primary">Salvar</Button>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = store => ({
    token: store.sessionState.token,
    user: store.sessionState.user
});

export default  connect(mapStateToProps)(AlterarSenha);
