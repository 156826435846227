import Dashboard from "./views/Dashboard.jsx";
import NovoTicket from "./views/solicitacoes/NovoTicket.jsx";
import Login from "./views/pages/Login.jsx";
import Acompanhamento from "./views/solicitacoes/Acompanhamento";
import Consulta from './views/solicitacoes/Consulta';
import AdminUsuarios from './views/auth/Usuarios';
import AdminGrupos from './views/auth/Grupos';
import AlterarSenha from './views/auth/AlterarSenha';
import UnAuth from './views/UnAuth.jsx';
import ResetPass from './views/pages/ResetPass';
import ConfirmEmail from "./views/pages/ConfirmEmail";

const routes = [
  {
    path: "/unauthorized",
    name: "Não Autorizado",
    icon: "nc-icon nc-bank",
    component: UnAuth,
    layout: "/auth",
    groups: "All",
    permission: ["All"]
  },
  {
    path: "/dashboard",
    name: "Início",
    icon: "nc-icon nc-chart-bar-32",
    component: Dashboard,
    layout: "/admin",
    groups: "All",
    permission: ["All"]
  },
  {
    path: "/dashboard",
    name: "Início",
    icon: "nc-icon nc-chart-bar-32",
    component: Dashboard,
    layout: "/portal",
    groups: "All",
    permission: ["All"]
  },
  {
    path: "/login",
    name: "Login",
    mini: "L",
    component: Login,
    layout: "/auth",
    redirect: true,
    groups: "All",
    permission: []
  },
  {
    path: "/reset-pass",
    name: "Reset Password",
    component: ResetPass,
    layout: "/auth",
    redirect: true,
    groups: "All",
    permission: []
  },
  {
    path: "/confirm-email",
    name: "Confirmação de email",
    component: ConfirmEmail,
    layout: "/auth",
    redirect: true,
    groups: "All",
    permission: []
  },
  {
    collapse: true,
    name: "Solicitações",
    icon: "nc-icon nc-headphones",
    state: "pagesCollapse",
    layout: "/admin",
    permission: ["All"],
    views: [
      {
        path: "/novo-ticket",
        name: "Nova solicitação",
        mini: "NS",
        component: NovoTicket,
        layout: "/admin",
        groups: "All",
        permission: ["All"]
      },
      {
        path: "/acompanhamento",
        name: "Acompanhamento",
        mini: "A",
        component: Acompanhamento,
        layout: "/admin",
        groups: "All",
        permission: ["All"]
      },
      {
        path: "/consulta",
        name: "Consulta",
        mini: "C",
        component: Consulta,
        layout: "/admin",
        groups: "All",
        permission: ["All"]
      }
    ]
  },
  {
    collapse: true,
    name: "Administrativo",
    icon: "nc-icon nc-book-bookmark",
    state: "pagesCollapse",
    layout: "/admin",
    permission: ["Administração"],
    views: [
      {
        path: "/usuarios",
        name: "Usuários",
        mini: "U",
        component: AdminUsuarios,
        layout: "/admin",
        groups: "All",
        permission: ["Administração"]
      },
      {
        path: "/grupos",
        name: "Grupos",
        mini: "G",
        component: AdminGrupos,
        layout: "/admin",
        groups: "All",
        permission: ["Administração"]
      },
    ]
  },
  {
    collapse: true,
    name: "Solicitações",
    icon: "nc-icon nc-headphones",
    state: "pagesCollapse",
    layout: "/portal",
    permission: ["All"],
    views: [
      {
        path: "/novo-ticket",
        name: "Nova solicitação",
        mini: "NS",
        component: NovoTicket,
        layout: "/portal",
        groups: "All",
        permission: ["All"]
      },
      {
        path: "/acompanhamento",
        name: "Acompanhamento",
        mini: "A",
        component: Acompanhamento,
        layout: "/portal",
        groups: "All",
        permission: ["All"]
      },
      {
        path: "/consulta",
        name: "Consulta",
        mini: "C",
        component: Consulta,
        layout: "/portal",
        groups: "All",
        permission: ["All"]
      }
    ]
  },
  {
    collapse: true,
    name: "Minha conta",
    icon: "nc-icon nc-single-02",
    state: "pagesCollapse",
    layout: "/portal",
    permission: ["All"],
    views: [
      {
        path: "/alterar-senha",
        name: "Alterar senha",
        mini: "AS",
        component: AlterarSenha,
        layout: "/portal",
        groups: "All",
        permission: ["All"]
      },
    ]
  }
];

export default routes;
