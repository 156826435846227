import React from "react";

class Loader2 extends React.Component{
    render(){
        return (<>
                        <div
                            style={{
                                backgroundColor: "black",
                                opacity: 0.4,
                                position: "fixed",
                                left: 0,
                                right: 0,
                                top: 0,
                                bottom: 0,
                                zIndex: 5000
                            }}
                        />
                        <div
                            style={{
                                backgroundColor: "white",
                                opacity: 1,
                                width: 150,
                                padding: 17,
                                borderRadius: 5,
                                textAlign: "center",
                                position: "fixed",
                                left: "50%",
                                top: "50%",
                                marginLeft: -75,
                                marginTop: -50,
                                overflow: "hidden",
                                zIndex: 5500,
                                display: "block"
                            }}
                        >

                                <img
                                    src={require("../../assets/img/loading.svg")}
                                />

                        </div>
                    </>);
    }
}
export default Loader2;