import React from "react";
import ReactTable from "react-table";
import { connect } from "react-redux";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    FormGroup,
    Input,
    Row,
    Col
} from "reactstrap";
import axios from "axios";
import { config } from "../../config";
import moment from "moment";
import Loader from "react-loader-spinner";
import { solicitacoes } from "../../helpers/autoatendimento/solicitacoes";
import TipoPessoaForm from "./TipoPessoaForm";
import ModalSolicitacao from "./ModalSolicitacao";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { sendGetRequest } from "../../helpers/request";
import Select from "react-select";
import Loader2 from "../components/Loading";

class Acompanhamento extends React.Component {
    constructor(props) {
        super(props);

        //const user = this.getAuthenticatedUser();
        var data = [];

        //console.log('acompanhamento props', this.props);

        this.state = {
            data,
            usuario: {
                username: "",
                fullname: "",
                groups: []
            }, //this.props.user,
            cliente_cnpj_cpf: "",
            loading: true,
            editform: null,
            editformIsOpen: true,
            motivoOptions: [],
            submotivoOptions: [],
            motivoSolicitacao: null,
            submotivoSolicitacao: null,
            alert: null,
            pagQtdeOptions: [
                { label: "5 linhas", value: 5 },
                { label: "10 linhas", value: 10 },
                { label: "20 linhas", value: 20 },
                { label: "25 linhas", value: 25 },
                { label: "50 linhas", value: 50 },
                { label: "100 linhas", value: 100 }
            ],
            pagQtde: { label: "10 linhas", value: 10 },
            pagNum: 1,
            btnAntDisabled: true,
            btnProxDisabled: true,
            pagTotal: 1,
            registrosTotal: 10,
            offset: 0
            // pagParamsLimit: 'limit=10&offset=10'
        };
    }

    atualizaTotalPaginas = () => {
        const pagTotal = parseInt(
            Math.ceil(this.state.registrosTotal / this.state.pagQtde.value)
        );
        //console.log('pagTotal1', pagTotal);
        //console.log('this.state.registrosTotal', this.state.registrosTotal);
        //console.log('this.state.pagQtde.value', this.state.pagQtde.value);
        this.setState({ pagTotal });
    };

    loadSolicitacoes = async (limit = 10, offset = 0) => {
        const self = this;
        var data = [];
        var registrosTotal = 0;

        this.setState({
            loading: true
        });

        if (self.state.usuario) {
            const deptos = this.state.usuario.groups.map(m => m.name); //.map(item => item.name);
            //console.log('deptos', deptos);
            //console.log('self.state.usuario', self.state.usuario);

            await sendGetRequest(
                config.API +
                    config.SOLICITACOES_FILTER_EMAIL +
                    self.state.usuario.username +
                    "&limit=" +
                    limit +
                    "&offset=" +
                    offset
            ).then(result => {
                if (result && result.results.length) {
                    //console.log('results', result.results);

                    result.results.forEach(item => {
                        data.push({
                            id: item.id,
                            status: (
                                <div
                                    style={{ margin: 0 }}
                                    className={
                                        "btn btn-" + item.status.cor + " btn-sm"
                                    }
                                    onClick={() => self.onClickItem(item.id)}
                                >
                                    {item.status.descricao}
                                </div>
                            ),
                            departamento: item.departamento.descricao,
                            numero_solicitacao: item.numero_solicitacao,
                            created_at: moment(
                                item.data_abertura,
                                "YYYY-MM-DDTHH:mm:ss"
                            ).format("DD/MM/YYYY HH:mm"),
                            tipo: item.submotivo.motivo,
                            motivo: item.submotivo.descricao,
                            requerente: item.requerente.nome,
                            cnpj_cpf: item.requerente.cnpj_cpf,
                            isDepartamento: false,
                            status_id: item.status.id
                        });
                    });
                    if (result.next) self.setState({ btnProxDisabled: false });
                    else self.setState({ btnProxDisabled: true });
                    if (result.previous)
                        self.setState({ btnAntDisabled: false });
                    else self.setState({ btnAntDisabled: true });
                    registrosTotal = result.count;
                }


                if (deptos.length > 0) {
                    //console.log('URL departamentos', config.API + config.SOLICITACOES_FILTER_DEPARTAMENTOS + deptos + '&limit=' + limit + '&offset=' + offset);
                    sendGetRequest(
                        config.API +
                            config.SOLICITACOES_FILTER_DEPARTAMENTOS +
                            deptos.join(",") +
                            "&limit=" +
                            limit +
                            "&offset=" +
                            offset +
                            "&status_id_not_in_array=5,6"
                    ).then(result => {
                        if (result && result.results.length) {
                            //console.log('results', result.results);
                            result.results.forEach(item => {
                                data.push({
                                    id: item.id,
                                    status: (
                                        <div
                                            style={{ margin: 0 }}
                                            className={
                                                "btn btn-" +
                                                item.status.cor +
                                                " btn-sm"
                                            }
                                            onClick={() =>
                                                self.onClickItem(item.id)
                                            }
                                        >
                                            {item.status.descricao}
                                        </div>
                                    ),
                                    departamento: item.departamento.descricao,
                                    numero_solicitacao: item.numero_solicitacao,
                                    created_at: moment(
                                        item.data_abertura,
                                        "YYYY-MM-DDTHH:mm:ss"
                                    ).format("DD/MM/YYYY HH:mm"),
                                    tipo: item.submotivo.motivo,
                                    motivo: item.submotivo.descricao,
                                    requerente: item.requerente.nome,
                                    cnpj_cpf: item.requerente.cnpj_cpf,
                                    isDepartamento: true,
                                    status_id: item.status.id
                                });
                            });
                            if (result.next)
                                self.setState({ btnProxDisabled: false });
                            else self.setState({ btnProxDisabled: true });
                            if (result.previous)
                                self.setState({ btnAntDisabled: false });
                            else self.setState({ btnAntDisabled: true });
                            registrosTotal = result.count;
                        }

                        this.setState({ loading: false });
                    });
                }
            });



            this.setState({ data, registrosTotal });
            this.atualizaTotalPaginas();
        }
    };

    loadSolicitacoesByDocumento = async () => {
        const self = this;

        this.setState({
            loading: true
        });

        if (self.state.usuario) {
            const deptos = Array(
                this.state.usuario.groups.map(item => item.name)
            ).toString();
            sendGetRequest(
                config.API +
                    config.SOLICITACOES_BY_CLIENTE_CNPJ_CPF +
                    this.state.cliente_cnpj_cpf +
                    `&departamento=${deptos}`
            ).then(result => {
                const _data = result.results;
                if (_data != null) {
                    var data = this.state.data;
                    if (Array(_data).length > 0) {
                        console.log("results2", _data);

                        _data.forEach(item => {
                            data.push({
                                id: item.id,
                                status: (
                                    <div
                                        style={{ margin: 0 }}
                                        className={
                                            "btn btn-" + item.status.cor + " btn-sm"
                                        }
                                        onClick={() => self.onClickItem(item.id)}
                                    >
                                        {item.status.descricao}
                                    </div>
                                ),
                                departamento: item.departamento.descricao,
                                numero_solicitacao: item.numero_solicitacao,
                                created_at: moment(
                                    item.data_abertura,
                                    "YYYY-MM-DDTHH:mm:ss"
                                ).format("DD/MM/YYYY HH:mm"),
                                tipo: item.submotivo.motivo,
                                motivo: item.submotivo.descricao,
                                requerente: item.requerente.nome,
                                cnpj_cpf: item.requerente.cnpj_cpf
                            });
                        });
                    }
                }
                self.setState({ data, loading: false });
            });
        }
    };

    componentWillUnmount() {
        var id = window.setTimeout(null, 0);
        while (id--) {
            window.clearTimeout(id);
        }
    }

    autoCloseAlert = (title, message, showButton, tempo = 2000) => {
        this.setState({
            alert: (
                <ReactBSAlert
                    style={{ display: "block", marginTop: "-100px" }}
                    title={title}
                    onConfirm={() => this.hideAlert()}
                    showConfirm={showButton}
                >
                    {message}
                </ReactBSAlert>
            )
        });
        if (tempo !== 0) {
            setTimeout(this.hideAlert, tempo);
        }
    };

    hideAlert = () => {
        this.setState({
            alert: null
        });
    };

    successAlert = numero_solicitacao => {
        this.setState({
            alert: (
                <ReactBSAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Sucesso!"
                    onConfirm={() => this.hideAlert()}
                    confirmBtnBsStyle="info"
                >
                    Solicitação encaminhada com sucesso!
                </ReactBSAlert>
            )
        });
    };

    async componentDidMount() {
        const user = await this.getAuthenticatedUser();
        await this.setState({ loading: false, usuario: user });
        await this.loadSolicitacoes();
        //console.log('user.id', this.state.usuario.id)
    }

    async getAuthenticatedUser() {
        if (this.props.location.pathname.startsWith("/form")) {
            const params = new URL(document.location).searchParams;
            var email_user = params.get("mail_do_solicitante");
            var email = email_user.split(",");

            var groups = [];
            await sendGetRequest(
                config.API + "groups/user?email=" + email[0]
            ).then(result => {
                groups = result;
            });

            groups = groups == null ? [] : groups;
            console.log("groups null?", groups);

            var obj = {
                username: email[0],
                fullname: email[0],
                groups: groups
            };
            console.log("obj", obj);
            return obj;
        }

        //console.log('this.props.user', this.props.user);
        return this.props.user;
        //getAuthenticatedUser(this.props.token);
    }

    toggleEditForm = () => {
        //console.log("passei no toggl")
        this.setState({ editformIsOpen: false, editform: null });
    };

    async onClickItem(id) {
        const self = this;
        this.setState({ loading: true });

        await solicitacoes.getSubmotivos().then(submotivos => {
            self.setState({
                submotivoOptions: submotivos
            });

            const promisse = solicitacoes.getSolicitacao(id);

            promisse
                .then(sol => {
                    //console.log('submotivoOptions', self.state.submotivoOptions);
                    //console.log('sol', sol)
                    var contadorAtividades = 0;
                    self.setState({
                        editformIsOpen: true,
                        editform: (
                            <ModalSolicitacao
                                isOpen={true}
                                toggle={self.toggleEditForm}
                                className={self.props.className}
                                numero_solicitacao={sol.numero_solicitacao}
                                data_abertura={sol.data_abertura}
                                submotivo_motivo={sol.submotivo.motivo}
                                submotivo_descricao={sol.submotivo.descricao}
                                requerente_cnpj_cpf={sol.requerente.cnpj_cpf}
                                requerente_nome={sol.requerente.nome}
                                detalhamento={sol.detalhamento}
                                atividades={sol.atividades}
                                usuario={this.state.usuario}
                                solicitacao={sol}
                                reload={() => this.loadSolicitacoes()}
                                onEvento={() => {
                                    self.setState({
                                        loading: true
                                    });
                                }}
                                onEventoSucesso={msg => {
                                    self.toggleEditForm();
                                    self.successAlert(msg);
                                    this.loadSolicitacoes();
                                    self.setState({
                                        loading: false
                                    });
                                }}
                                onEventoErro={msg => {
                                    self.toggleEditForm();
                                    self.autoCloseAlert("Erro", msg, true, 0);
                                    this.loadSolicitacoes();
                                    self.setState({
                                        loading: false
                                    });
                                }}
                            />
                        )
                    });
                })
                .finally(() => {
                    this.setState({
                        loading: false
                    });
                });
        });
    }

    caseInsensitiveFilter = (filter, data) => {
        //console.log('caseInsensitiveFilter', x, y);
        //console.log('custom filter', data[filter.id]);
        return data[filter.id]
            .toLowerCase()
            .startsWith(filter.value.toLowerCase());
    };

    dataSortMethod = (a, b, desc) => {
        //console.log('passei no dataSortMethod', a, b, desc);
        a = a.substr(6, 4) + a.substr(3, 2) + a.substr(0, 2) + a.substr(10);
        b = b.substr(6, 4) + b.substr(3, 2) + b.substr(0, 2) + b.substr(10);
        if (a < b) return 1;
        if (a > b) return -1;
        return 0;
    };

    htmlFilter = (filter, data) => {
        //console.log('caseInsensitiveFilter', filter, data);
        //console.log('custom filter', data[filter.id].props.children);
        return data[filter.id].props.children
            .toLowerCase()
            .startsWith(filter.value.toLowerCase());
    };

    render() {
        console.log("this.state.pagQtde.value", this.state.pagQtde.value);
        console.log("this.state.data", this.state.data);
        return (
            <>
                {this.state.loading && (
                    <Loader2 />
                )}
                {this.state.editform}
                {this.state.alert}
                <div
                    className="content"
                    style={
                        this.props.location.pathname.startsWith("/form")
                            ? { marginTop: 0 }
                            : {}
                    }
                >
                    {!this.props.location.pathname.startsWith("/form") && (
                        <>
                            <h4>Acompanhamento de solicitações</h4>
                            <Card>
                                <CardBody>
                                    <TipoPessoaForm
                                        tipo={"Cliente"}
                                        onChange={documento =>
                                            this.setState({
                                                cliente_cnpj_cpf: documento
                                            })
                                        }
                                        atualizar={this.state.atualizar}
                                    />
                                    <Button
                                        className="btn-round"
                                        color="info"
                                        type="submit"
                                        onClick={() =>
                                            this.loadSolicitacoesByDocumento()
                                        }
                                    >
                                        Enviar
                                    </Button>
                                </CardBody>
                            </Card>
                        </>
                    )}
                    {this.state.usuario &&
                        this.state.usuario.groups.length > 0 && (
                            <Row>
                                <Col md={3}>
                                    <Button
                                        className="success"
                                        style={{ width: "100%" }}
                                        onClick={() => {
                                            const deptos = this.state.usuario.groups.map(
                                                item => item.name
                                            );
                                            setTimeout(() => {
                                                const response = {
                                                    file:
                                                        config.API +
                                                        "export/?departamento=" +
                                                        deptos
                                                };
                                                window.open(response.file);
                                            }, 100);
                                        }}
                                    >
                                        Exportar Excel
                                    </Button>
                                </Col>
                            </Row>
                        )}

                    <Row>
                        <Col md="12">
                            <Card>
                                <CardBody>
                                    <ReactTable
                                        data={this.state.data}
                                        filterable
                                        columns={[
                                            {
                                                Header: "Protocolo",
                                                accessor: "numero_solicitacao"
                                            },
                                            {
                                                Header: "Documento",
                                                accessor: "cnpj_cpf"
                                            },
                                            {
                                                Header: "Requerente",
                                                filterMethod: this
                                                    .caseInsensitiveFilter,
                                                accessor: "requerente"
                                            },

                                            /* {
                                                Header: "Categoria",
                                                accessor: "tipo"
                                            }, */
                                            {
                                                Header: "Motivo",
                                                filterMethod: this
                                                    .caseInsensitiveFilter,
                                                accessor: "motivo"
                                                /* sortable: false,
                                                filterable: false */
                                            },
                                            {
                                                Header: "Data criado",
                                                sortMethod: this.dataSortMethod,
                                                accessor: "created_at"
                                            },
                                            {
                                                Header: "Status",
                                                filterMethod: this.htmlFilter,
                                                accessor: "status"
                                            }
                                        ]}
                                        defaultPageSize={10}
                                        showPaginationTop={false}
                                        showPaginationBottom={false}
                                        pageSize={this.state.pagQtde.value}
                                        /*
                                          You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                                        */
                                        className="-striped -highlight none"
                                    />

                                    <Row>
                                        <Col md={3}>
                                            <Button
                                                className="info"
                                                disabled={
                                                    this.state.btnAntDisabled
                                                }
                                                style={{ width: "100%" }}
                                                onClick={() => {
                                                    const limit = this.state
                                                        .pagQtde.value;
                                                    const pagNum =
                                                        this.state.pagNum - 1;
                                                    const offset =
                                                        this.state.offset -
                                                        limit;
                                                    //console.log('limit', limit)
                                                    //console.log('pagNum', pagNum)
                                                    //console.log('offset', offset)
                                                    this.loadSolicitacoes(
                                                        limit,
                                                        offset
                                                    );
                                                    this.setState({
                                                        pagNum,
                                                        offset
                                                    });
                                                }}
                                            >
                                                Anterior
                                            </Button>
                                        </Col>
                                        <Col
                                            md={3}
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignContent: "center",
                                                alignItems: "center"
                                            }}
                                        >
                                            <div>Página</div>
                                            <div
                                                style={{
                                                    width: "70px",
                                                    marginLeft: "10px",
                                                    marginRight: "10px"
                                                }}
                                            >
                                                <Input
                                                    type="number"
                                                    value={this.state.pagNum}
                                                    onChange={event => {
                                                        console.log(
                                                            "this.state.pagTotal",
                                                            this.state.pagTotal
                                                        );
                                                        console.log(
                                                            "event.target.value",
                                                            event.target.value
                                                        );
                                                        console.log(
                                                            "this.state.offset",
                                                            this.state.offset
                                                        );
                                                        if (
                                                            event.target.value >
                                                                0 &&
                                                            event.target
                                                                .value <=
                                                                this.state
                                                                    .pagTotal
                                                        ) {
                                                            const limit = this
                                                                .state.pagQtde
                                                                .value;
                                                            const pagNum = parseInt(
                                                                event.target
                                                                    .value
                                                            );
                                                            var offset =
                                                                this.state
                                                                    .offset +
                                                                limit;
                                                            if (
                                                                pagNum <
                                                                this.state
                                                                    .pagNum
                                                            ) {
                                                                offset =
                                                                    this.state
                                                                        .offset -
                                                                    limit;
                                                            }
                                                            this.loadSolicitacoes(
                                                                limit,
                                                                offset
                                                            );
                                                            this.setState({
                                                                pagNum,
                                                                offset
                                                            });
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <div>de</div>
                                            <div style={{ marginLeft: "10px" }}>
                                                {this.state.pagTotal}
                                            </div>
                                        </Col>
                                        <Col
                                            md={3}
                                            style={{ paddingTop: "10px" }}
                                        >
                                            <Select
                                                name="pagQtde"
                                                value={this.state.pagQtde}
                                                onChange={event => {
                                                    this.loadSolicitacoes(
                                                        event.value
                                                    );
                                                    this.setState({
                                                        pagQtde: event,
                                                        offset: 0,
                                                        pagNum: 1
                                                    });
                                                }}
                                                options={
                                                    this.state.pagQtdeOptions
                                                }
                                                placeholder=""
                                            />
                                        </Col>
                                        <Col
                                            md={3}
                                            style={{ textAlign: "right" }}
                                        >
                                            <Button
                                                className="info"
                                                style={{ width: "100%" }}
                                                disabled={
                                                    this.state.btnProxDisabled
                                                }
                                                onClick={() => {
                                                    const limit = this.state
                                                        .pagQtde.value;
                                                    const pagNum =
                                                        this.state.pagNum + 1;
                                                    const offset =
                                                        this.state.offset +
                                                        limit;
                                                    this.loadSolicitacoes(
                                                        limit,
                                                        offset
                                                    );
                                                    this.setState({
                                                        pagNum,
                                                        offset
                                                    });
                                                }}
                                            >
                                                Próxima
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

const mapStateToProps = store => ({
    token: store.sessionState.token,
    user: store.sessionState.user
});

export default connect(mapStateToProps)(Acompanhamento);
