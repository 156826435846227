import React from "react";
import ReactTable from "react-table";
import { connect } from "react-redux";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col
} from "reactstrap";
import axios from 'axios';
import { config } from '../../config';
import moment from "moment";
import Loader from 'react-loader-spinner';
import { solicitacoes } from '../../helpers/autoatendimento/solicitacoes';
import ModalSolicitacao from './ModalSolicitacao'
import ReactBSAlert from "react-bootstrap-sweetalert";
import TipoPessoaForm from './TipoPessoaForm';
import { sendGetRequest } from '../../helpers/request';
import Loader2 from "../components/Loading";

class Consulta extends React.Component {
    constructor(props) {
        super(props);

        //const user = this.getAuthenticatedUser();
        var data = [];

        console.log('acompanhamento props', this.props);

        this.state = {
            data,
            usuario: this.props.user,
            loading: true,
            alert: null,
            cliente_cnpj_cpf: ''
        }

    }

    componentWillUnmount() {
        var id = window.setTimeout(null, 0);
        while (id--) {
            window.clearTimeout(id);
        }
    }

    autoCloseAlert = (title, message, showButton, tempo = 2000) => {
        this.setState({
            alert: (
                <ReactBSAlert
                    style={{ display: "block", marginTop: "-100px" }}
                    title={title}
                    onConfirm={() => this.hideAlert()}
                    showConfirm={showButton}
                >
                    {message}
                </ReactBSAlert>
            )
        });
        if (tempo !== 0) {
            setTimeout(this.hideAlert, tempo);
        }
    };

    hideAlert = () => {
        this.setState({
            alert: null
        });
    };

    successAlert = (numero_solicitacao) => {
        this.setState({
            alert: (
                <ReactBSAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Sucesso!"
                    onConfirm={() => this.hideAlert()}
                    confirmBtnBsStyle="info"
                >
                    Solicitação encaminhada com sucesso!
            </ReactBSAlert>
            )
        });
    };

    componentDidMount() {
        this.setState({
            loading: false
        });
    }

    toggleEditForm = () => {
        console.log("passei no toggl")
        this.setState({ editformIsOpen: false, editform: null });
    }

    onClickItem(id) {
        const self = this;

        solicitacoes.getSubmotivos().then(submotivos => {
            self.setState({
                submotivoOptions: submotivos,
                loading: false
            });

            const promisse = solicitacoes.getSolicitacao(id);

            promisse.then(sol => {
                //console.log('submotivoOptions', self.state.submotivoOptions);
                //console.log('sol', sol)
                var contadorAtividades = 0;
                self.setState({
                    editformIsOpen: true,
                    editform: <ModalSolicitacao
                        isOpen={true}
                        toggle={self.toggleEditForm}
                        className={self.props.className}
                        numero_solicitacao={sol.numero_solicitacao}
                        data_abertura={sol.data_abertura}
                        submotivo_motivo={sol.submotivo.motivo}
                        submotivo_descricao={sol.submotivo.descricao}
                        requerente_cnpj_cpf={sol.requerente.cnpj_cpf}
                        requerente_nome={sol.requerente.nome}
                        detalhamento={sol.detalhamento}
                        atividades={sol.atividades}
                        usuario={this.state.usuario}
                        solicitacao={sol}
                        reload={() => this.loadSolicitacoes()}
                        onEvento={() => {
                            self.setState({
                                loading: true
                            });
                        }}
                        read_only={true}
                    />
                });
            });
        });
    }


    loadSolicitacoes = () => {
        const self = this;

        this.setState({
            loading: true
        });

        console.log('state', this.state);
        console.log('usuario', this.state.usuario);
        if (self.state.usuario) {


                sendGetRequest(config.API + config.SOLICITACOES_BY_CLIENTE_CNPJ_CPF + this.state.cliente_cnpj_cpf).then(result => {
                    var data = [];

                    console.log('results1', result.results);

                    if (result && result.results.length) {
                        console.log('results2', result.results);

                        result.results.forEach(item => {
                            data.push({
                                id: item.id,
                                status: <div style={{ margin: 0 }} className={'btn btn-' + item.status.cor + ' btn-sm'} onClick={() => self.onClickItem(item.id)}>{item.status.descricao}</div>,
                                departamento: item.departamento.descricao,
                                numero_solicitacao: item.numero_solicitacao,
                                created_at: moment(item.data_abertura, "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY HH:mm"),
                                tipo: item.submotivo.motivo,
                                motivo: item.submotivo.descricao,
                                requerente: item.requerente.nome,
                                cnpj_cpf: item.requerente.cnpj_cpf
                            });
                        });
                    }
                    self.setState({ data, loading: false });
                });

        }
    }

    caseInsensitiveFilter = (filter, data) => {
        //console.log('caseInsensitiveFilter', x, y);
        //console.log('custom filter', data[filter.id]);
        return (data[filter.id].toLowerCase().startsWith(filter.value.toLowerCase()));
    }

    dataSortMethod = (a, b, desc) => {
        //console.log('passei no dataSortMethod', a, b, desc);
        a = a.substr(6, 4) + a.substr(3, 2) + a.substr(0, 2) + a.substr(10);
        b = b.substr(6, 4) + b.substr(3, 2) + b.substr(0, 2) + b.substr(10);
        if (a < b) return 1;
        if (a > b) return -1;
        return 0;
    }

    htmlFilter = (filter, data) => {
        //console.log('caseInsensitiveFilter', filter, data);
        //console.log('custom filter', data[filter.id].props.children);
        return (data[filter.id].props.children.toLowerCase().startsWith(filter.value.toLowerCase()));
    }

    render() {
        return (
            <>
                {this.state.loading && (
                    <Loader2 />
                )}
                {this.state.editform}
                {this.state.alert}
                <div className="content" >
                    <h4>Consulta de solicitações</h4>
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardBody>
                                    <TipoPessoaForm
                                        tipo={"Cliente"}
                                        onChange={(documento) => this.setState({ cliente_cnpj_cpf: documento })}
                                        atualizar={this.state.atualizar}
                                    />
                                    <Button
                                        className="btn-round"
                                        color="info"
                                        type="submit"
                                        onClick={this.loadSolicitacoes}
                                    >
                                        Enviar
                                    </Button>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <Card>

                                <CardBody>
                                    <ReactTable
                                        data={this.state.data}
                                        filterable
                                        columns={[
                                            {
                                                Header: "Protocolo",
                                                accessor: "numero_solicitacao"
                                            },
                                            {
                                                Header: "Documento",
                                                accessor: "cnpj_cpf"
                                            },
                                            {
                                                Header: "Requerente",
                                                filterMethod: this.caseInsensitiveFilter,
                                                accessor: "requerente"
                                            },

                                            /* {
                                                Header: "Categoria",
                                                accessor: "tipo"
                                            }, */
                                            {
                                                Header: "Motivo",
                                                filterMethod: this.caseInsensitiveFilter,
                                                accessor: "motivo",
                                                /* sortable: false,
                                                filterable: false */
                                            },
                                            {
                                                Header: "Depto.",
                                                filterMethod: this.caseInsensitiveFilter,
                                                accessor: "departamento",
                                            },
                                            {
                                                Header: "Data criado",
                                                sortMethod: this.dataSortMethod,
                                                accessor: "created_at"
                                            },
                                            {
                                                Header: "Status",
                                                filterMethod: this.htmlFilter,
                                                accessor: "status"
                                            }
                                        ]}
                                        defaultPageSize={10}
                                        showPaginationTop={false}
                                        showPaginationBottom={true}
                                        /*
                                          You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                                        */
                                        className="-striped -highlight primary-pagination"
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

// export default Acompanhamento;

/* export default connect(
    (sessions) => (sessions),
    null
)(Acompanhamento); */

const mapStateToProps = store => ({
    token: store.sessionState.token,
    user: store.sessionState.user
});

export default connect(mapStateToProps)(Consulta);
