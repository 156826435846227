import React from "react";

// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";

import axios from 'axios';
import { config } from '../../config';

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    CardText,
    Label,
    FormGroup,
    Form,
    Input,
    FormText,
    Row,
    Col,
    TabContent, TabPane, Nav, NavItem, NavLink,
    Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";

// import ImageUpload from "components/CustomUpload/AnnexUpload.jsx";
import InputMask from 'react-input-mask';
import Dropzone from 'react-dropzone';
import moment from "moment";
// import connect from "redux";
import classnames from 'classnames';
import ReactBSAlert from "react-bootstrap-sweetalert";

import { connect } from "react-redux";
import { getAuthenticatedUser } from "../../helpers/auth/auth";
import { sendGetRequest } from '../../helpers/request';

import Loader from 'react-loader-spinner';
import TipoPessoaForm from './TipoPessoaForm';
import ChipForm from './ChipForm';
import SolicitanteForm from './SolicitanteForm';
import MaquinaForm from './MaquinaForm';
import Loader2 from "../components/Loading";

const initialAddress = {
    endereco_diferente: false,
    endereco_diferente_logradouro: '',
    endereco_diferente_numero: '',
    endereco_diferente_complemento: '',
    endereco_diferente_cep: '',
    endereco_diferente_bairro: '',
    endereco_diferente_cidade: '',
    endereco_diferente_estado: ''
}

const initialState = {
    motivoSolicitacao: {
        value: 1
    },
    submotivoSolicitacao: null,
    quantidadeBobinas: { value: "3", label: "Até 3" },
    detalhamento: "",
    files: [],
    requerente_cnpj_cpf: "",
    activeTab: "3",
    atualizar: true,
    avisado: false,
    loading: true,
    telefone_contato: '',
    ...initialAddress,
    operadora_fields: [
        { idx: 0, operadora: null, numero_chip: '' }
    ],
    maquina_fields: [
        { idx: 0, operadora: null, numero_chip: '', numero_serie: '', maquina: null }
    ],
    faq: null,
    faqIsOpen: true,
    email_clone: '',
    is_endereco_diferente_logradouro_travado: true,
    is_endereco_diferente_bairro_travado: true,
    is_endereco_diferente_cidade_travado: true,
    is_endereco_diferente_estado_travado: true
};

class NovoTicket extends React.Component {
    constructor(props) {
        super(props);

        const user = this.getAuthenticatedUser();

        //console.log('user', user);
        //console.log('API SOL', config.API);

        this.state = {
            ...initialState,
            usuario: user,
            alert: null,
            quantidadeBobinasOptions: [
                { value: "3", label: "Até 3" },
                { value: "6", label: "4 - 6" },
                { value: "9", label: "7 - 9" },
                { value: "12", label: "10 - 12" },
                { value: "15", label: "13 - 15" },
            ],
            motivoOptions: [],
            submotivoOptions: [],
            solicitante_cnpj_cpf: "",
            optionsParaQuem: [
                { value: "2", label: "Agente" },
                { value: "3", label: "Cliente" },
                { value: "1", label: "Para mim" }
            ],
            pessoa_tipo: 3,
            atualizar: false,
            searchCepResult: {
                isValid: true,
                mensagem: ''
            },

            // operadora: null
        }
    }

    searchCep = (cep) => {
        this.setState({
            loading: true
        })
        this.resetSearchCep();
        axios.get(config.API_VIACEP + cep + "/json/", {
            onUploadProgress: ProgressEvent => {
                this.setState({
                    loaded: (ProgressEvent.loaded / ProgressEvent.total * 100),
                })
            }
        }).then(response => {
            if (!(response.data.erro)) {
                var res = response.data;

                const is_endereco_diferente_logradouro_travado = (res.logradouro == '' ? false : true);
                const is_endereco_diferente_bairro_travado = (res.bairro == '' ? false : true);
                const is_endereco_diferente_cidade_travado = (res.localidade == '' ? false : true);
                const is_endereco_diferente_estado_travado = (res.uf == '' ? false : true);

                this.setState({
                    loading: false,
                    endereco_diferente_logradouro: res.logradouro,
                    endereco_diferente_bairro: res.bairro,
                    endereco_diferente_cidade: res.localidade,
                    endereco_diferente_estado: res.uf,
                    endereco_diferente_cep: cep,
                    searchCepResult: {
                        isValid: true,
                        mensagem: ''
                    },
                    is_endereco_diferente_logradouro_travado,
                    is_endereco_diferente_bairro_travado,
                    is_endereco_diferente_cidade_travado,
                    is_endereco_diferente_estado_travado
                })
            } else {
                this.setState({
                    loading: false,
                    searchCepResult: {
                        isValid: false,
                        mensagem: 'Cep inválido!'
                    }
                })
            }
        }).catch(res => {
            this.setState({
                loading: false,
                searchCepResult: {
                    isValid: false,
                    mensagem: 'Ocorreu um erro ao consultar o cep informado'
                }
            })
        });
    }

    resetSearchCep = () => {
        this.setState({
            ...initialAddress
        })
    }

    getAuthenticatedUser() {
        if (this.props.location.pathname.startsWith('/form')) {
            const params = (new URL(document.location)).searchParams;
            return {
                username: params.get("mail_do_solicitante"),
                fullname: params.get("mail_do_solicitante")
            };
        }

        //console.log('this.props.user', this.props.user);
        return this.props.user;
        //getAuthenticatedUser(this.props.token);
    }

    successAlert = (numero_solicitacao) => {
        this.setState({
            alert: (
                <ReactBSAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Sucesso!"
                    onConfirm={() => this.hideAlert()}
                    confirmBtnBsStyle="info"
                >
                    Solicitação <b>#{numero_solicitacao}</b> cadastrada com sucesso!
            </ReactBSAlert>
            )
        });
    };

    autoCloseAlert = (title, message, showButton, tempo = 2000) => {
        this.setState({
            alert: (
                <ReactBSAlert
                    style={{ display: "block", marginTop: "-100px" }}
                    title={title}
                    onConfirm={() => this.hideAlert()}
                    showConfirm={showButton}
                >
                    {message}
                </ReactBSAlert>
            )
        });
        if (tempo !== 0) {
            setTimeout(this.hideAlert, tempo);
        }
    };

    hideAlert = () => {
        this.setState({
            alert: null
        });
    };

    // to stop the warning of calling setState of unmounted component
    componentWillUnmount() {
        this.unblock();
        /* var id = window.setTimeout(null, 0);
        while (id--) {
            window.clearTimeout(id);
        } */
    }

    componentDidMount() {

        var self = this;
        this.unblock = this.props.history.block(targetLocation => {
            if (targetLocation.pathname != '/admin/novo-ticket') {
                if (!self.state.avisado) {
                    self.autoCloseAlert('Possível perda de dados!', 'Deseja sair mesmo assim?', true, 0);
                    self.setState({ avisado: true });
                    return false;
                }
                return true;
            }
        });

        // Se pessoa tipo = 3 (cliente), configura CPF/CNPJ como sendo dele mesmo
        if (this.state.pessoa_tipo == 3) this.setState({ requerente_cnpj_cpf: this.state.solicitante_cnpj_cpf });

        sendGetRequest(config.API + config.MOTIVOS_QUERY).then(result => {
            //console.log('result', result.data);
            var submotivoOptions = [];
            var motivoOptions = [];
            if (result && result.results.length) {
                result.results.forEach((m) => {
                    motivoOptions.push({ value: m.id, label: m.descricao });
                    m.submotivos.forEach((sm) => {
                        //console.log('sm', sm);
                        if (sm.ativo) {
                            submotivoOptions.push({ value: sm.id, label: sm.descricao, foreignkey: m.id });
                        }
                    });
                });
            }
            self.setState({
                submotivoOptions,
                motivoOptions,
                loading: false
            });
        });
    }

    onDrop = (files) => {
        this.setState({ files });
    }

    onValidationError = () => {
        this.setState({
            loading: false
        });
    };

    submitForm = () => {

        // Ligando spinner
        this.setState({
            loading: true
        });

        // Validando dados
        var mail_do_solicitante = this.state.usuario ? this.state.usuario.username : null;

        const is_atendimento = mail_do_solicitante == 'atendimento@contalucree.com.br' || mail_do_solicitante == 'atendimento.lucree'
                                || mail_do_solicitante == 'suporte@contalucree.com.br' || mail_do_solicitante == 'suporte.lucree'
                                || mail_do_solicitante == 'cadastro@contalucree.com.br' || mail_do_solicitante == 'cadastro.lucree';

        if (is_atendimento) {

            if (this.state.email_clone == '') {
                this.autoCloseAlert('Preencha o E-mail', 'O e-mail do solicitante é obrigatório!', true, 0);
                return;
            }

            if (this.state.email_clone != '') {
                mail_do_solicitante = this.state.email_clone;
            }
        }

        //console.log('mail_do_solicitante', mail_do_solicitante);

        // Validando email do solicitante
        if (!mail_do_solicitante) {
            this.autoCloseAlert('Sessão Expirada', 'Você não está loggado!', true, 0);
            this.onValidationError();
            return;
        }

        // Validando o motivo
        if (!this.state.submotivoSolicitacao) {
            this.autoCloseAlert('Selecione o Motivo', 'Motivo não selecionado!', true, 0);
            this.onValidationError();
            return;
        }

        // Validando CPF/CNPJ
        var isRequerenteCnpjCpfValid = true;
        var requerente_cnpj_cpf = this.state.requerente_cnpj_cpf;
        if ((this.state.activeTab != "2" && this.state.activeTab != "3")) {
            requerente_cnpj_cpf = this.state.solicitante_cnpj_cpf;
        } else {
            if (requerente_cnpj_cpf == "") {
                isRequerenteCnpjCpfValid = false;
            }
        }
        if (!isRequerenteCnpjCpfValid) {
            this.autoCloseAlert('Preencha o CPF/CNPJ', 'Campo CPF/CNPJ não preenchido!', true, 0);
            this.onValidationError();
            return;
        }

        // Validando tamanho dos arquivos
        if (this.state.files.length) {
            var size = 0;
            for (var i = 0; i < this.state.files.length; i++) {
                const file = this.state.files[i];
                size += file.size;
            }

            if (size > config.LIMITE_UPLOAD_BYTES) {
                this.autoCloseAlert('Erro', 'Arquivos de upload excedem o limite de '
                                                + config.LIMITE_UPLOAD_MB + '!', true, 0);
                this.onValidationError();
                return;
            }
        }

        // Validando campos do fluxo de máquina ou de chip
        // if ([25, 24, 26, 27].includes(this.state.submotivoSolicitacao.value)) {
        //     for (var i = 0; i < this.state.maquina_fields.length; i++) {
        //         if ([24, 26, 27].includes(this.state.submotivoSolicitacao.value)) {
        //             if (this.state.maquina_fields[i].maquina == null) {
        //                 this.autoCloseAlert('Selecione o Tipo de Máquina', 'O campo tipo de máquina é obrigatório!', true, 0);
        //                  this.onValidationError();
        //                 return;
        //             }
        //             if (this.state.maquina_fields[i].numero_serie == '') {
        //                 this.autoCloseAlert('Preencha o Número de Série', 'O campo número de série da máquina é obrigatório!', true, 0);
        //                  this.onValidationError();
        //                 return;
        //             }
        //         }
        //         const campo = this.state.submotivoSolicitacao.value == 25
        //             ? this.state.operadora_fields : this.state.maquina_fields;
        //         if (campo[i].operadora == null) {
        //             this.autoCloseAlert('Selecione a Operadora', 'O campo operadora é obrigatório!', true, 0);
        //              this.onValidationError();
        //             return;
        //         }
        //         if (campo[i].numero_chip == '') {
        //             this.autoCloseAlert('Preencha o Número do Chip', 'O campo número do chip é obrigatório!', true, 0);
        //              this.onValidationError();
        //             return;
        //         }
        //     }
        // }

        // configurando obrigatoriedade do endereço conforme o status
        // this.state.endereco_diferente = [19, 25, 24, 26, 27].includes(this.state.submotivoSolicitacao.value);

        // if (this.state.endereco_diferente) {
        //     // Validando o endereço de entrega
        //     if (this.state.endereco_diferente_cep == '') {
        //         this.autoCloseAlert('Preencha o CEP', 'CEP do endereço não preenchido!', true, 0);
        //          this.onValidationError();
        //         return;
        //     }
        //     if (this.state.endereco_diferente_logradouro == '') {
        //         this.autoCloseAlert('Consulta do Endereço', 'Consulta do endereço não efetuada!', true, 0);
        //          this.onValidationError();
        //         return;
        //     }
        //     if (this.state.endereco_diferente_numero == '') {
        //         this.autoCloseAlert('Preencha o Número', 'Número do endereço não preenchido!', true, 0);
        //          this.onValidationError();
        //         return;
        //     }
        // }

        // Validando o telefone
        if (this.state.telefone_contato == '') {
            this.autoCloseAlert('Preencha o Telefone de Contato', 'Telefone de Contato não preenchido!', true, 0);
            this.onValidationError();
            return;
        }



        // Enviando outros dados do chamado
        const postdata = {
            detalhamento: this.state.detalhamento,
            numero_solicitacao: "",
            identificacao_chamado: "",
            data_fechamento: null,
            requerente_cnpj_cpf: requerente_cnpj_cpf,
            solicitante_cnpj_cpf: null,
            solicitante_email: mail_do_solicitante ? mail_do_solicitante : "",
            responsavel_cnpj_cpf: "",
            submotivo_id: this.state.submotivoSolicitacao.value,
            status_id: null,
            telefone_contato: this.state.telefone_contato,
            endereco_diferente: this.state.endereco_diferente,
            endereco_diferente_logradouro: this.state.endereco_diferente_logradouro,
            endereco_diferente_numero: this.state.endereco_diferente_numero,
            endereco_diferente_complemento: this.state.endereco_diferente_complemento,
            endereco_diferente_cep: this.state.endereco_diferente_cep,
            endereco_diferente_bairro: this.state.endereco_diferente_bairro,
            endereco_diferente_cidade: this.state.endereco_diferente_cidade,
            endereco_diferente_estado: this.state.endereco_diferente_estado
        }

        var self = this;
        var headers = {
            'Content-Type': 'application/json',
            'Authorization': config.AUTH_TOKEN
        }
        axios.post(config.API + 'solicitacoes/', postdata, { headers: headers })
            .then(function (response) {

                // Enviando campos de chips
                // if ([25].includes(self.state.submotivoSolicitacao.value)) {
                //     if (self.state.operadora_fields.length) {
                //         self.state.operadora_fields.forEach(o => {
                //             if (o.operadora) {
                //                 const postdata = {
                //                     solicitacao_id: response.data.id,
                //                     tipo_id: 2,
                //                     descricao: o.numero_chip + ' - ' + o.operadora.value,
                //                     analista_departamento: self.state.usuario.fullname
                //                 }
                //                 axios.post(config.API + 'campos/', postdata, { headers: headers });
                //             }
                //         });
                //     }
                // }

                // Enviando campos de máquinas
                // if ([24, 26, 27].includes(self.state.submotivoSolicitacao.value)) {
                //     if (self.state.maquina_fields.length) {
                //         self.state.maquina_fields.forEach(o => {
                //             if (o.operadora) {
                //                 var postdata = {
                //                     solicitacao_id: response.data.id,
                //                     tipo_id: 1,
                //                     descricao: o.maquina.value + ' - ' + o.numero_serie,
                //                     analista_departamento: self.state.usuario.fullname
                //                 }
                //                 axios.post(config.API + 'campos/', postdata, { headers: headers });
                //                 postdata = {
                //                     solicitacao_id: response.data.id,
                //                     tipo_id: 2,
                //                     descricao: o.numero_chip + ' - ' + o.operadora.value,
                //                     analista_departamento: self.state.usuario.fullname
                //                 }
                //                 axios.post(config.API + 'campos/', postdata, { headers: headers });
                //             }
                //         });
                //     }
                // }

                // Enviando anexos
                if (self.state.files.length) {
                    self.state.files.forEach(file => {
                        const data = new FormData();
                        data.append('path', file, file.name);
                        data.append('descricao', file.name);
                        data.append('solicitacao_id', response.data.id);
                        console.log('anexo api', config.API + config.ANEXOS_QUERY);
                        axios.post(config.API + config.ANEXOS_QUERY, data, {
                            onUploadProgress: ProgressEvent => {
                                self.setState({
                                    loaded: (ProgressEvent.loaded / ProgressEvent.total * 100),
                                })
                            },
                            headers: headers,
                        }).then(res => {
                            //console.log(res.statusText)
                            self.successAlert(response.data.numero_solicitacao);
                            self.setState(initialState);
                            self.setState({
                                loading: false
                            });
                        }).catch(error => {
                            self.autoCloseAlert('Erro', 'Erro enviando anexos da solicitação!', true, 0);
                            self.setState({
                                loading: false
                            });
                        })
                    });
                } else {
                    self.successAlert(response.data.numero_solicitacao);
                    self.setState(initialState);
                    self.setState({
                        loading: false
                    });
                }
            })
            .catch(function (error) {
                var alert = false;
                if (error.response) {
                    console.log(error.response);
                    if (error.response.status == 400) {
                        alert = true;
                        self.autoCloseAlert('Erro', error.response.data.message, true, 0);
                    }
                    if (error.response.status == 413) {
                        alert = true;
                        self.autoCloseAlert('Erro', 'Não foi possível fazer upload. Arquivo anexo muito grande!', true, 0);
                    }
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('Error', error.message);
                }
                console.log(error);

                if (!alert) {
                    self.autoCloseAlert('Erro', 'Erro enviando solicitação!', true, 0);
                }
                self.setState({
                    loading: false
                });
            });
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    chipAdd = () => {
        var novoArray = [
            ...this.state.operadora_fields,
            { idx: this.state.operadora_fields.length, operadora: null, numero_chip: '' }
        ];
        this.setState({ operadora_fields: novoArray });
    }

    chipUpdate = (value, item) => {
        value.idx = item.idx;
        var novoArray = [
            ...this.state.operadora_fields.filter(f => f.idx != item.idx),
            value
        ];
        this.setState({ operadora_fields: novoArray });
    }

    chipRemove = (item) => {
        var novoArray = [
            ...this.state.operadora_fields.filter(f => f.idx != item.idx)
        ];
        this.setState({ operadora_fields: novoArray });
    }

    maqAdd = () => {
        var novoArray = [
            ...this.state.maquina_fields,
            { idx: this.state.maquina_fields.length, operadora: null, numero_chip: '', numero_serie: '', maquina: null }
        ];
        this.setState({ maquina_fields: novoArray });
    }

    maqUpdate = (value, item) => {
        value.idx = item.idx;
        var novoArray = [
            ...this.state.maquina_fields.filter(f => f.idx != item.idx),
            value
        ];
        this.setState({ maquina_fields: novoArray });
    }

    maqRemove = (item) => {
        var novoArray = [
            ...this.state.maquina_fields.filter(f => f.idx != item.idx)
        ];
        this.setState({ maquina_fields: novoArray });
    }

    toggleFaq = () => {
        //console.log('passei no toggle faq');
        this.setState({ faqIsOpen: false, faq: null });
    }

    onChangeMotivo = (value) => {
        const self = this;
        this.setState({ submotivoSolicitacao: value });
        if (this.state.motivoSolicitacao.value == 1) {
            sendGetRequest(config.API + config.FAQ_QUERY).then(result => {
                //console.log('result FAQ', result);
                //console.log('subMotivos options', self.state.submotivoOptions);
                //console.log('subMotivos selecionado', value.value);
                if (result && result.results) {
                    const duvida = result.results.find(f => f.submotivo == value.value);
                    if (duvida) {
                        //self.autoCloseAlert('FAQ - Dúvidas', duvida.descricao, true, 0);
                        //console.log('result FAQ --- passei aqui', self.state.faqIsOpen ? 'sim' : 'não');
                        const faq =
                            <Modal isOpen={true} toggle={self.toggleFaq}>
                                <ModalHeader>FAQ - Dúvidas</ModalHeader>
                                <ModalBody>
                                    <Row>
                                        <Col md={12}>
                                            <h6>{self.state.submotivoOptions.find(f => f.value == value.value).label}</h6>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <p dangerouslySetInnerHTML={{ __html: result.results.find(f => f.submotivo == value.value).descricao }} />
                                        </Col>
                                    </Row>
                                </ModalBody>
                                <ModalFooter>
                                    <Button color="secondary" onClick={self.toggleFaq} style={{ marginRight: '20px' }}>Fechar</Button>
                                </ModalFooter>
                            </Modal>;

                        self.setState({ faqIsOpen: true, faq });
                    }
                }
            });
        }
    }

    clearUploadFiles = (e) => {
        e.preventDefault();
        this.setState({ files: [] });
    }

    render() {

        const mail_do_solicitante = this.state.usuario ? this.state.usuario.username : '';
        const documento_cpf_cnpj = null;

        const files = this.state.files.map(file => (
            <li key={file.name}>
                {file.name} - {file.size} bytes
            </li>
        ))

        var pessoa_tipo = this.state.pessoa_tipo;
        const paraQuem = this.state.optionsParaQuem.filter(f => {
            if (pessoa_tipo == 6 || pessoa_tipo == 7) return (f.value != "2");
            else return true;
        });

        return (
            <>
                {this.state.loading && (
                    <Loader2 />
                )}

                {this.state.alert}
                {this.state.faq}
                <div className="content" style={this.props.location.pathname.startsWith('/form') ? { marginTop: 0 } : {}} >
                    <h4>Atendimento</h4>
                    <Row>
                        <Col md="12">
                            {this.state.motivoSolicitacao
                                && this.state.motivoSolicitacao.value != 1
                                && this.state.pessoa_tipo != 3 && (
                                <>
                                    <p>Para quem é a solicitação?</p>
                                    <Card>
                                        <CardBody>
                                            <Form action="#" method="#">
                                                <div>
                                                    <Nav className="nav-pills-primary nav-pills-icons justify-content-center"
                                                        pills
                                                        role="tablist"
                                                    >
                                                        {paraQuem.map(e => {
                                                            return (<NavItem key={e.value}>
                                                                <NavLink
                                                                    className={classnames({ active: this.state.activeTab === e.value })}
                                                                    onClick={() => {
                                                                        this.toggle(e.value);
                                                                    }}
                                                                >
                                                                    {e.label}
                                                                </NavLink>
                                                            </NavItem>)
                                                        })}
                                                    </Nav>
                                                    <TabContent activeTab={this.state.activeTab}>
                                                        <TabPane tabId="1">
                                                            <Row>
                                                                <Col sm="12">
                                                                    <SolicitanteForm
                                                                        mail_do_solicitante={mail_do_solicitante}
                                                                        onChange={(documento, pessoa_tipo) => {
                                                                            console.log('pessoa_tipo', pessoa_tipo);
                                                                            this.setState({ solicitante_cnpj_cpf: documento, pessoa_tipo })
                                                                        }}
                                                                        tipo={2}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </TabPane>
                                                        <TabPane tabId="2">
                                                            <Row>
                                                                <Col sm="12">
                                                                    <TipoPessoaForm
                                                                        tipo={"Agente"}
                                                                        onChange={(documento) => this.setState({ requerente_cnpj_cpf: documento })}
                                                                        atualizar={this.state.atualizar}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </TabPane>
                                                        <TabPane tabId="3">
                                                            <Row>
                                                                <Col sm="12">
                                                                    <TipoPessoaForm
                                                                        tipo={"Cliente"}
                                                                        onChange={(documento) => this.setState({ requerente_cnpj_cpf: documento })}
                                                                        atualizar={this.state.atualizar}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </TabPane>
                                                    </TabContent>
                                                </div>
                                            </Form>
                                        </CardBody>
                                    </Card>
                                </>
                            )}

                            <p>Escolha a opção que melhor define sua solicitação</p>

                            <Row>
                                {this.state.motivoOptions.map(item => {
                                    const fator = 12 / this.state.motivoOptions.length;
                                    return (
                                        <Col md={fator}>
                                            <Card>
                                                <CardBody>
                                                    <div className="form-check-radio">
                                                        <Label check>
                                                            <Input
                                                                checked={item.value == (this.state.motivoSolicitacao ? parseInt(this.state.motivoSolicitacao.value) : 1)}
                                                                defaultValue={item.value}
                                                                name={"radioMotivo"}
                                                                type="radio"
                                                                onChange={(event) => {
                                                                    //console.log('motivoSolicitacao', event.target);
                                                                    this.setState({ motivoSolicitacao: event.target, submotivoSolicitacao: null })
                                                                }}
                                                            />
                                                            <span className="form-check-sign" />
                                                        </Label>
                                                    </div>
                                                    <CardText>{item.label}</CardText>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    )
                                })}
                            </Row>

                            <p>Adicione os detalhes da solicitação</p>

                            <Card>
                                <CardBody>
                                    <Form action="#" method="#">
                                        <FormGroup>
                                            <label>Motivo</label>
                                            <Select
                                                className="react-select primary"
                                                classNamePrefix="react-select"
                                                name="submotivoSolicitacao"
                                                value={this.state.submotivoSolicitacao}
                                                onChange={this.onChangeMotivo}
                                                options={this.state.submotivoOptions.filter(
                                                    f => f.foreignkey === (this.state.motivoSolicitacao ? parseInt(this.state.motivoSolicitacao.value) : 0)
                                                )}
                                                placeholder=""
                                            />
                                        </FormGroup>
                                        {this.state.motivoSolicitacao
                                            && this.state.motivoSolicitacao.value != 1
                                            && (
                                                <FormGroup>
                                                    <label>Detalhamento</label>
                                                    <Input type="textarea"
                                                        value={this.state.detalhamento}
                                                        onChange={(event) => this.setState({ detalhamento: event.target.value })}
                                                    />
                                                    <FormText color="default" tag="span">
                                                        Fique a vontade para descrever aqui seu chamado.
                                                        Este não é um campo obrigatório.
                                                    </FormText>
                                                </FormGroup>
                                            )}
                                    </Form>
                                </CardBody>
                            </Card>

                            {this.state.submotivoSolicitacao
                                && this.state.submotivoSolicitacao.value == 999 && ( // ajuste de subs
                                    <>
                                        <p>Digite os dados do equipamento com problema</p>
                                        <Card>
                                            <CardBody>
                                                <Form action="#" method="#">
                                                    {this.state.operadora_fields.map(item =>
                                                        <ChipForm
                                                            item={item}
                                                            onAdd={this.chipAdd}
                                                            onUpdate={(value) => this.chipUpdate(value, item)}
                                                            onRemove={() => this.chipRemove(item)}
                                                        />
                                                    )}
                                                </Form>
                                            </CardBody>
                                        </Card>
                                    </>
                                )}

                            {this.state.submotivoSolicitacao
                                && [999].includes(this.state.submotivoSolicitacao.value) && ( // ajustar subs
                                    <>
                                        <p>Digite os dados do equipamento com problema</p>
                                        <Card>
                                            <CardBody>
                                                <Form action="#" method="#">
                                                    {this.state.maquina_fields.map(item =>
                                                        <MaquinaForm
                                                            item={item}
                                                            onAdd={this.maqAdd}
                                                            onUpdate={(value) => this.maqUpdate(value, item)}
                                                            onRemove={() => this.maqRemove(item)}
                                                        />
                                                    )}
                                                </Form>
                                            </CardBody>
                                        </Card>
                                    </>
                                )}

                            {this.state.submotivoSolicitacao
                                && [999].includes(this.state.submotivoSolicitacao.value) && ( // ajustar subs
                                    <>
                                        {this.state.submotivoSolicitacao.value == 19 && (
                                            <p>Digite o endereço para entrega em caso de necessidade de envio de suprimento</p>
                                        )}
                                        {this.state.submotivoSolicitacao.value != 19 && (
                                            <p>Digite o endereço para entrega em caso de necessidade de envio de equipamento</p>
                                        )}
                                        <Card>
                                            <CardBody>
                                                <Form action="#" method="#">
                                                    <Row>
                                                        <Col md="6">
                                                            <FormGroup>
                                                                <label>CEP</label>
                                                                <Input
                                                                    type="text"
                                                                    value={this.state.endereco_diferente_cep}
                                                                    onChange={(event) => this.setState({ endereco_diferente_cep: event.target.value })}
                                                                    className="form-control"
                                                                />
                                                                {!this.state.searchCepResult.isValid && (
                                                                    <FormText color="danger" tag="span">
                                                                        {this.state.searchCepResult.mensagem}
                                                                    </FormText>
                                                                )}
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="2">
                                                            <FormGroup>
                                                                <Button style={{ marginTop: "24px" }} onClick={() => this.searchCep(this.state.endereco_diferente_cep)} color="success">
                                                                    Consultar
                                                                </Button>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md="12">
                                                            <FormGroup>
                                                                <label>Logradouro</label>
                                                                <Input
                                                                    type="text"
                                                                    value={this.state.endereco_diferente_logradouro}
                                                                    className="form-control"
                                                                    onChange={(event) => {
                                                                        if (!this.state.is_endereco_diferente_logradouro_travado) {
                                                                            this.setState({ endereco_diferente_logradouro: event.target.value });
                                                                        }
                                                                    }}

                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md="6">
                                                            <FormGroup>
                                                                <label>Número</label>
                                                                <Input
                                                                    type="text"
                                                                    value={this.state.endereco_diferente_numero}
                                                                    onChange={(event) => this.setState({ endereco_diferente_numero: event.target.value })}
                                                                    className="form-control"
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="6">
                                                            <FormGroup>
                                                                <label>Complemento</label>
                                                                <Input
                                                                    type="text"
                                                                    value={this.state.endereco_diferente_complemento}
                                                                    onChange={(event) => this.setState({ endereco_diferente_complemento: event.target.value })}
                                                                    className="form-control"
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md="12">
                                                            <FormGroup>
                                                                <label>Bairro</label>
                                                                <Input
                                                                    type="text"
                                                                    value={this.state.endereco_diferente_bairro}
                                                                    className="form-control"
                                                                    onChange={(event) => {
                                                                        if (!this.state.is_endereco_diferente_bairro_travado) {
                                                                            this.setState({ endereco_diferente_bairro: event.target.value });
                                                                        }
                                                                    }}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md="6">
                                                            <FormGroup>
                                                                <label>Cidade</label>
                                                                <Input
                                                                    type="text"
                                                                    value={this.state.endereco_diferente_cidade}
                                                                    className="form-control"
                                                                    onChange={(event) => {
                                                                        if (!this.state.is_endereco_diferente_cidade_travado) {
                                                                            this.setState({ endereco_diferente_cidade: event.target.value });
                                                                        }
                                                                    }}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="6">
                                                            <FormGroup>
                                                                <label>Estado</label>
                                                                <Input
                                                                    type="text"
                                                                    value={this.state.endereco_diferente_estado}
                                                                    className="form-control"
                                                                    onChange={(event) => {
                                                                        if (!this.state.is_endereco_diferente_estado_travado) {
                                                                            this.setState({ endereco_diferente_estado: event.target.value });
                                                                        }
                                                                    }}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>

                                                </Form>
                                            </CardBody>
                                            <CardFooter>

                                            </CardFooter>
                                        </Card>
                                    </>
                                )}

                            {/* {this.state.motivoSolicitacao && this.state.submotivoSolicitacao
                                && this.state.motivoSolicitacao.value != 1
                                && this.state.submotivoSolicitacao.value != 19 && ( */}
                                    {this.state.motivoSolicitacao
                                && this.state.motivoSolicitacao.value != 1
                                && (<>
                                        <p>Insira documentos ou fotos que auxiliem na explicação da
                                                                        solicitação</p>

                                        <Card>
                                            <CardBody>
                                                <Form action="#" method="#">
                                                    <FormGroup>
                                                        <Dropzone onDrop={this.onDrop}>
                                                            {({ getRootProps, getInputProps, isDragActive }) => {
                                                                return (
                                                                    <div
                                                                        {...getRootProps()}
                                                                        className={"form-control"}
                                                                    >
                                                                        <input {...getInputProps()} />
                                                                        {
                                                                            <p>
                                                                                <i className="nc-icon nc-cloud-upload-94"></i>&nbsp;&nbsp;
                                                                        <span style={{ borderBottomWidth: 1, borderBottomColor: 'black', borderBottomStyle: 'dotted' }}>
                                                                                    Arraste arquivos aqui para anexar, ou clique para pesquisar</span>

                                                                                {files.length > 0 && (
                                                                                    <FormGroup>
                                                                                        <FormText color="default" tag="span">
                                                                                            <label>Arquivos</label>
                                                                                            <ul>{files}</ul>
                                                                                        </FormText>
                                                                                        <a href="#" onClick={this.clearUploadFiles}>
                                                                                            Resetar anexos
                                                                                        </a>
                                                                                    </FormGroup>
                                                                                )}
                                                                            </p>
                                                                        }
                                                                    </div>
                                                                )
                                                            }}
                                                        </Dropzone>
                                                        <FormText color="default" tag="span">
                                                            Em caso de solicitação de alteração de domicílio bancário é
                                                            necessário enviar comprovante bancário da nova conta.
                                                        </FormText>
                                                    </FormGroup>
                                                </Form>
                                            </CardBody>
                                        </Card>
                                    </>
                                )}

                            {this.state.motivoSolicitacao
                                && this.state.motivoSolicitacao.value != 1
                                && (
                                    <>
                                        <p>Dados do solicitante</p>
                                        <Card>
                                            <CardBody>
                                                <Form action="#" method="#">
                                                    <SolicitanteForm
                                                        mail_do_solicitante={mail_do_solicitante ? mail_do_solicitante : 'Campo preenchido de forma automática.'}
                                                        documento_cpf_cnpj={documento_cpf_cnpj ? documento_cpf_cnpj : 'Campo preenchido de forma automática.'}
                                                        onChange={(documento, pessoa_tipo) => {
                                                            this.setState({ solicitante_cnpj_cpf: documento, pessoa_tipo })
                                                            if (pessoa_tipo == 3) {
                                                                this.setState({ requerente_cnpj_cpf: documento })
                                                            }
                                                        }}
                                                        tipo={2}
                                                        is_atendimento={mail_do_solicitante == 'atendimento@contalucree.com.br' || mail_do_solicitante == 'atendimento.contalucree'
                                                                        || mail_do_solicitante == 'suporte@contalucree.com.br' || mail_do_solicitante == 'suporte.contalucree'
                                                                        || mail_do_solicitante == 'cadastro@contalucree.com.br' || mail_do_solicitante == 'cadastro.contalucree'}
                                                        onChangeEmail={(event) => {
                                                            /* const usuario = {
                                                                ...this.state.usuario,
                                                                username: event.target.value
                                                            }
                                                            console.log('usuario email', usuario); */
                                                            this.setState({ email_clone: event.target.value });
                                                        }}
                                                    />
                                                    <Row>
                                                        <Col md="6">
                                                            <FormGroup>
                                                                <label>Telefone de contato</label>
                                                                <InputMask
                                                                    type="text"
                                                                    value={this.state.telefone_contato}
                                                                    onChange={(event) => this.setState({ telefone_contato: event.target.value })}
                                                                    mask={"(99) 99999-9999"}
                                                                    className="form-control"
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </CardBody>
                                            <CardFooter>

                                            </CardFooter>
                                        </Card>
                                        <Button
                                            className="btn-round"
                                            color="info"
                                            type="submit"
                                            onClick={this.submitForm}
                                        >
                                            Enviar
                                        </Button>
                                    </>
                                )}
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

const mapStateToProps = store => ({
    token: store.sessionState.token,
    user: store.sessionState.user
});

export default connect(mapStateToProps)(NovoTicket);
